<template>
  <router-view />
  <a-layout style="min-height: 100vh" v-if="Logined">
    <a-layout-sider v-model:collapsed="collapsed" width="250"  theme="light">
      <div class="logo"><img src="./assets/logo.png"></div>
      <a-affix :offset-top="20">
        <a-menu v-model:selectedKeys="selectedKeys" v-model:openKeys="openKeys" theme="light" mode="inline">
          <a-sub-menu :key="m.id" v-for="m in Menus">
            <template #icon>
              <component :is="m.ico"></component>
            </template>
            <template #title>
              <span>{{ m.title }}</span>
            </template>
            <template v-for="sub in m.sub">
              <router-link v-if="(sub.url != 'SUB')" :to="sub.url">
                <a-menu-item :key="sub.id">{{ sub.title }}</a-menu-item>
              </router-link>
              <a-sub-menu v-if="sub.url == 'SUB'" :key="sub.id">
                <template #title>{{ sub.title }}</template>
                <router-link v-for="subitem in sub.subitem" :to="subitem.url">
                  <a-menu-item :key="subitem.id">{{ subitem.title }}</a-menu-item>
                </router-link>
              </a-sub-menu>
            </template>
          </a-sub-menu>
        </a-menu>
      </a-affix>

    </a-layout-sider>
    <a-layout>
      <!--标题-->
      <a-layout-header
        style="background: #fff; display: flex;justify-content: space-between;align-items: center;padding: 0 20px;">
        <menu-fold-outlined style="font-size:1.4rem" v-if="!collapsed" @click="() => (collapsed = !collapsed)" />
        <menu-unfold-outlined style="font-size:1.4rem" v-if="collapsed" @click="() => (collapsed = !collapsed)" />
        <a-dropdown>
          <template #overlay>
            <a-menu>
              <a-menu-item>
                <a href="javascript:;">个人中心</a>
              </a-menu-item>
              <a-menu-item>
                <a href="javascript:sessionStorage.clear();window.location='/'">退出</a>
              </a-menu-item>
            </a-menu>
          </template>
          <a-button v-if="userInfor">
            你好，{{userInfor.account}} 管理员 
            <DownOutlined />
          </a-button>
        </a-dropdown>
      </a-layout-header>
      <!--内容-->
      <a-layout-content style="margin:10px">
        <!-- <a-breadcrumb style="margin: 16px 0">
          <a-breadcrumb-item>{{nav[0]}}</a-breadcrumb-item>
          <a-breadcrumb-item>{{nav[1]}}</a-breadcrumb-item>
        </a-breadcrumb> -->
        <div
          :style="{ padding: '10px 10px 30px 10px', margin: '10px 10px 20px 10px', background: '#fff', boxShadow: '0 10px 20px rgba(0,0,0,0.15)', borderRadius: '6px' }">
          <router-view name="main"></router-view>
        </div>
      </a-layout-content>
      <a-layout-footer style="text-align: center">
        Created by ZFeng Design 2022
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>
<script>
import {
  PieChartOutlined,
  DesktopOutlined,
  UserOutlined,
  TeamOutlined,
  StarOutlined,
  ReadOutlined,
  DownOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  DollarCircleOutlined,
  BookOutlined,
  ProfileOutlined
} from "@ant-design/icons-vue";
import { defineComponent, ref,getCurrentInstance } from "vue";
import { Menus } from "./ext/menu";
import req from "@/ext/request"; // 引入封装得axios

export default defineComponent({
  components: {
    PieChartOutlined,
    DesktopOutlined,
    UserOutlined,
    TeamOutlined,
    StarOutlined,
    ReadOutlined,
    DownOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    DollarCircleOutlined,
    BookOutlined,
    ProfileOutlined
  },

  data() {
    return {
      Logined: false,
      collapsed: ref[true],
      selectedKeys: ['sys-state'],
      openKeys: ['system'],
      Menus,
      userInfor:getCurrentInstance().appContext.config.globalProperties.$userinfo,
    };
  },
  computed: {
  },
  created() {
    // console.log(this.Menus)
    if (sessionStorage.token) {
      this.$router.push('/')
      this.Logined=true
      var data = {
        token:sessionStorage.token
      }


      // req({
      //   url: "/Core.asmx/GetAdminInf",
      //   method: "POST",
      //   data,
      // }).then((e) => {
      //   var result = e.d
      //   console.log(result)
      //   if (result.code == 0) {
      //     this.userInfor = JSON.parse(result.data)
      //     // this.userInfor.groupid=parseInt(this.userInfor.groupid)
      //     this.Logined = true
          
      //     // console.log(this.userInfor)
      //     // console.log(this.dataSource)
      //   }
      //   else
      //   {
      //     sessionStorage.clear()
      //     this.Logined = false
      //     this.$router.push('/')

      //   }
      // });

    }
    else {
      this.Logined = false
    }
  },
  mounted() {

    console.log("Mounted")
  },
  methods: {
    test() {
      var menu
      var sub
      this.Menus.forEach(m => {
        var tmp = m.sub.filter(s => s.id == this.selectedKeys[0])
        if (tmp.length > 0) {
          menu = m;
          sub = tmp;
          return
        }
      });

      // console.log(sub)
      // console.log(menu)

    }
  }
});
</script>

<style>
</style>
