//路由表
import { createRouter, createWebHashHistory } from "vue-router";
const Login = () => import("@/pages/Login");

const routes = [
  {
    path: "/",
    redirect: "/System",
  },
  {
    path: "/Login",
    components: {
      default: Login,
    },
  },
  {
    path: "/System",
    components: {
      main: () => import("@/pages/System/infor"),
    },
  },
  {
    path: "/System/Setting",
    components: {
      main: () => import("@/pages/System/setting"),
    },
  },
  {
    path: "/System/Account",
    components: {
      main: () => import("@/pages/System/account"),
    },
  },
  {
    path: "/System/Lev",
    components: {
      main: () => import("@/pages/System/lev"),
    },
  },
  //论坛管理
  {
    path: "/BBS/Class",
    components: {
      main: () => import("@/pages/System/setting"),
    },
  },
  {
    path: "/BBS/List",
    components: {
      main: () => import("@/pages/System/setting"),
    },
  },
  //订单管理
  {
    path: "/Order/Detail",
    components: {
      main: () => import("@/pages/Order/detail"),
    },
  },
  //店铺
  {
    path: "/Shop/OnSell",
    components: {
      main: () => import("@/pages/System/setting"),
    },
  },
  {
    path: "/Shop/Class",
    components: {
      main: () => import("@/pages/System/setting"),
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  //判断目标路由的meta元字符中是否匹配限制
  //console.log(to);
  if (to.path == "/Login") {
    //console.log("LOGIN中");
    next();
  } else {
    //判断是否已登录
    if (sessionStorage.getItem("token") == null) {
      //跳转到登录页携带参数
      next("/Login");
      //console.log("需要登录");
    } else {
      next();
    }
  }
});

export default router;
