import Vue, { createApp } from "vue";
import Antd from "ant-design-vue";
import App from "./App";
import "ant-design-vue/dist/antd.css";
import "./assets/css.css";
import VueClipBoard from "vue-clipboard2";
import router from "./components/router";

const app = createApp(App);

app.use(VueClipBoard);
app.use(Antd).use(router).mount("#app");
//全局变量
app.config.globalProperties.$webURL = "http://admin.xiaoniupw.cn";