const Menus = [
  {
    id: "system",
    title: "平台管理",
    ico: "PieChartOutlined",
    sub: [
      {
        id: "sys-state",
        title: "平台状态",
        url: "/System",
      },
      {
        id: "sys-setting",
        title: "参数设置",
        url: "/System/Setting",
      }      
    ],
  },
  {
    id: "account",
    title: "账号管理",
    ico: "UserOutlined",
    sub: [
      {
        id: "account-user",
        title: "会员账号",
        url: "/System/Account",
      },
      {
        id: "account-lev",
        title: "等级设置",
        url: "/System/Lev",
      },
    ],
  },
  {
    id: "order",
    title: "交易管理",
    ico: "DollarCircleOutlined",
    sub: [
      {
        id: "order-list",
        title: "订单明细",
        url: "/Order/Detail",
      },
    ],
  },
  {
    id: "bbs",
    title: "论坛管理",
    ico: "BookOutlined",
    sub: [
      {
        id: "bbs-list",
        title: "论坛帖子",
        url: "/BBS/List",
      },
      {
        id: "bbs-class",
        title: "分区管理",
        url: "/BBS/Class",
      },
    ],
  },
  {
    id: "shop",
    title: "商城管理",
    ico: "ProfileOutlined",
    sub: [
      {
        id: "shop-product",
        title: "在售产品",
        url: "/Shop/OnSell",
      },
      {
        id: "shop-class",
        title: "产品分类",
        url: "/Shop/Class",
      },
    ],
  },
];

export { Menus };
